import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeRoutingModule } from '../home/home-routing.module';
import { HomeComponent } from '../home/home.component';
import { TopRatedComponent } from './top-rated/top-rated.component';
import { AboutComponent } from './about/about.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ArticlesModule } from '../articles/articles.module';
import { GeneralModule } from '../general/general.module';


@NgModule({
  declarations: [
    HomeComponent,
    TopRatedComponent,
    AboutComponent,
    

  ],
  imports: [
    CommonModule,
    HomeRoutingModule,
    FontAwesomeModule,
   
    GeneralModule
  ],
 
})
export class HomeModule { }