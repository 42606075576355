
<app-home-banner [pageType]="'homepage'"  [BannerHeading]="bannerHeading" [BannerImage]="bannerImage"></app-home-banner>
<section id="top-articles" class="container-fluid">
    <div class="top-articles">
        <div class="section-heading" tabindex="0" *ngIf="!pageloading;else PageTitleNotLoaded">
            <h1> What's new</h1>
        </div>
        <ng-template #PageTitleNotLoaded>
            <div class="section-heading skeleton" tabindex="0" >
                <!-- <h1> What's new</h1> -->
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </div>
        </ng-template>
        <app-top-rated *ngIf="!pageTopRatedLoading;else PageTopRatedNotLoaded" [pageloading]="pageTopRatedLoading"></app-top-rated>
        <ng-template  #PageTopRatedNotLoaded>
            <app-top-rated  [pageloading]="pageTopRatedLoading"></app-top-rated>
        </ng-template>
        <div class="row mt-xxl-1 mt-xl-3 mt-lg-3 mt-md-4 mt-sm-4 mt-xs-4 d-flex justify-content-center" *ngIf="!articlePageLoading;else PageArticleNotLoaded">
            <div *ngFor="let article of articles;let i=index" class="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-6 card-gap ps-3  " [ngClass]="i == 0 || i == 4 ? 'ps-3' : (i == 3 || i == 7 ? 'pe-3' : '')">
                <app-article-card-list [article]="article"
                    [index]="i"></app-article-card-list>
                <!-- <div class="card article-card" >
                    
                    <img src="article1.png"
                        class="card-img-top article-img" alt="BJS article cover image">
                    <div class="card-body">
                        <div class="ellipsis-div">
                            <h2 class="card-title">The Surgical Research Society BJS
                                Lecture 2024: Future surgical
                            </h2>
                            <p class="card-text">Professor Stephen Wigmore, KHS</p>
                            <p class="card-content">The BJS Academy are delighted to
                                showcase the BJS Lecture, delivered by
                                Stephen Wigmore, at the Surgical</p>
                        </div>
                        <div class="card-tag">
                            <a href="#" class="tag-content">Video</a>
                            <a href="#" class="tag-content">General</a>
                        </div>
                        <span class="card-date"><fa-icon class="me-2" [icon]="['fas','calendar-days']"></fa-icon>28 May 2024</span>
                    </div>
                </div> -->
            </div>
            <!-- <div class="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-6 card-gap ">
                <div class="card article-card">
                    <img src="Young-BJS-Thumbnail.jpg"
                        class="card-img-top article-img" alt="BJS">
                    <div class="card-body">
                        <div class="ellipsis-div">
                            <h2 class="card-title">Foundation models: the future of
                                surgical artificial intelligence?</h2>
                            <p class="card-text">Lam K, Qui J. </p>
                            <p class="card-content">My name is Malin Sund. I work as professor of surgery and consultant
                                of
                                surgery at the University of Helsinki and Helsinki University Hospital in Finland, and
                                as
                                guest professor of surgery at Umeå University in Sweden. I was invited to write this
                                piece
                                about why I became a breast surgeon. The question is interesting since I came to realize
                                that perhaps this was more serendipity than an active pursuit. During surgical training,
                                I
                                found most clinical subspecialities interesting. I am also quite sure that if I had
                                chosen
                                another field of surgery, I would have been equally content.</p>
                        </div>
                        <div class="card-tag">
                            <a href="#" class="tag-content">Breast</a>
                        </div>
                        <span class="card-date"><fa-icon class="me-2" [icon]="['fas','calendar-days']"></fa-icon>27 May 2024</span>
                    </div>
                </div>
            </div>
            <div class="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-6 card-gap ">
                <div class="card article-card">
                    <img src="article-3.png"
                        class="card-img-top article-img" alt="BJS">
                    <div class="card-body">
                        <div class="ellipsis-div">
                            <h2 class="card-title">Editing in surgery: publisher</h2>
                            <p class="card-content">BJS Academy is excited to offer ‘Editing in
                                Surgery, a series of course videos from
                                our BJS Institute partnership with the
                                University of Edinburgh and the
                                Edinburgh Surgery Online team. Our..</p>
                        </div>
                        <div class="card-tag">
                            <a href="#" class="tag-content">video</a>
                            <a href="#" class="tag-content">general</a>
                        </div>
                        <span class="card-date"><fa-icon class="me-2" [icon]="['fas','calendar-days']"></fa-icon>23 May 2024</span>
                    </div>
                </div>
            </div>
            <div class="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-6 card-gap side-padding-right">
                <div class="card article-card">
                    <img src="article4.png"
                        class="card-img-top article-img" alt="BJS">
                    <div class="card-body">
                        <div class="ellipsis-div">
                            <h2 class="card-title">BJS Academy SoMe review April
                                2024
                            </h2>
                            <p class="card-text">Isaac Mendoza, Hernando Thorne...</p>
                            <p class="card-content">Ensuring the FLR is adequate is an important consideration as a poor
                                decision can lead to significant postoperative morbidity and risk of mortality due to
                                liver failure. CT volumetry offers a degree of reassurance, but better functional tests
                                for FLR are needed.</p>
                        </div>
                        <div class="card-tag">
                            <a href="#" class="tag-content">Colorectal</a>
                        </div>
                        <span class="card-date"><fa-icon class="me-2" [icon]="['fas','calendar-days']"></fa-icon>17 March 2024</span>
                    </div>
                </div>
            </div>
            <div class="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-6 card-gap side-padding-left">
                <div class="card article-card">
                    <img src="article1.png"
                        class="card-img-top article-img" alt="BJS">
                    <div class="card-body">
                        <div class="ellipsis-div">
                            <h2 class="card-title">The Surgical Research Society BJS
                                Lecture 2024: Future surgical
                            </h2>
                            <p class="card-text">Professor Stephen Wigmore, KHS</p>
                            <p class="card-content">The BJS Academy are delighted to
                                showcase the BJS Lecture, delivered by
                                Stephen Wigmore, at the Surgical</p>
                        </div>
                        <div class="card-tag">
                            <a href="#" class="tag-content">Video</a>
                            <a href="#" class="tag-content">General</a>
                        </div>
                        <span class="card-date"><fa-icon class="me-2" [icon]="['fas','calendar-days']"></fa-icon>28 May 2024</span>
                    </div>
                </div>
            </div>
            <div class="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-6 card-gap ">
                <div class="card article-card">
                    <img src="Young-BJS-Thumbnail.jpg"
                        class="card-img-top article-img" alt="BJS">
                    <div class="card-body">
                        <div class="ellipsis-div">
                            <h2 class="card-title">Foundation models: the future of
                                surgical artificial intelligence?</h2>
                            <p class="card-text">Lam K, Qui J. </p>
                            <p class="card-content">My name is Malin Sund. I work as professor of surgery and consultant
                                of
                                surgery at the University of Helsinki and Helsinki University Hospital in Finland, and
                                as
                                guest professor of surgery at Umeå University in Sweden. I was invited to write this
                                piece
                                about why I became a breast surgeon. The question is interesting since I came to realize
                                that perhaps this was more serendipity than an active pursuit. During surgical training,
                                I
                                found most clinical subspecialities interesting. I am also quite sure that if I had
                                chosen
                                another field of surgery, I would have been equally content.</p>
                        </div>
                        <div class="card-tag">
                            <a href="#" class="tag-content">Breast</a>
                        </div>
                        <span class="card-date"><fa-icon class="me-2" [icon]="['fas','calendar-days']"></fa-icon>27 May 2024</span>
                    </div>
                </div>
            </div>
            <div class="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-6 card-gap ">
                <div class="card article-card">
                    <img src="article-3.png"
                        class="card-img-top article-img" alt="BJS">
                    <div class="card-body">
                        <div class="ellipsis-div">
                            <h2 class="card-title">Editing in surgery: publisher</h2>
                            <p class="card-content">BJS Academy is excited to offer ‘Editing in
                                Surgery, a series of course videos from
                                our BJS Institute partnership with the
                                University of Edinburgh and the
                                Edinburgh Surgery Online team. Our..</p>
                        </div>
                        <div class="card-tag">
                            <a href="#" class="tag-content">video</a>
                            <a href="#" class="tag-content">general</a>
                        </div>
                        <span class="card-date"><fa-icon class="me-2" [icon]="['fas','calendar-days']"></fa-icon>23 May 2024</span>
                    </div>
                </div>
            </div>
            <div class="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-6 card-gap side-padding-right">
                <div class="card article-card">
                    <img src="article4.png"
                        class="card-img-top article-img" alt="BJS">
                    <div class="card-body">
                        <div class="ellipsis-div">
                            <h2 class="card-title">BJS Academy SoMe review April
                                2024
                            </h2>
                            <p class="card-text">Isaac Mendoza, Hernando Thorne...</p>
                            <p class="card-content">Ensuring the FLR is adequate is an important consideration as a poor
                                decision can lead to significant postoperative morbidity and risk of mortality due to
                                liver failure. CT volumetry offers a degree of reassurance, but better functional tests
                                for FLR are needed.</p>
                        </div>
                        <div class="card-tag">
                            <a href="#" class="tag-content">Colorectal</a>
                        </div>
                        <span class="card-date"><fa-icon class="me-2" [icon]="['fas','calendar-days']"></fa-icon>17 March 2024</span>
                    </div>
                </div>
            </div> -->
        </div>
        <ng-template #PageArticleNotLoaded>
            <div class="row mt-xxl-1 mt-xl-3 mt-lg-3 mt-md-4 mt-sm-4 mt-xs-4 d-flex justify-content-center" >
                <div  *ngFor="let each of articleListarray;let i=index" class="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-6 card-gap ps-3  "   [ngClass]="i == 0 || i == 4 ? 'ps-3' : (i == 3 || i == 7 ? 'pe-3' : '')">
                    <app-article-card-list [pageloading]="articlePageLoading"></app-article-card-list>
                   
                </div>
            </div>
        </ng-template>
    </div>

</section>
<section class="position-absolute billboard-section">
    <app-billboard  (BillboardHeight)="getBillboardBoxWidth($event)" *ngIf="!billboardPageLoading; else pageBillBoaredNotLoaded"></app-billboard>
    <ng-template #pageBillBoaredNotLoaded>
        <app-billboard  (BillboardHeight)="getBillboardBoxWidth($event)" [pageloading]="billboardPageLoading"></app-billboard>
    </ng-template>
</section>
<!-- <div id="billboard" class="container-fluid">
    <div class="billboard">
        <div class="billboard-box">
            <div class="billboard-add">
                <h3 class="advertisement text-center mt-4 pt-4 mb-0">Billboard
                </h3>
                <p class="text-center">970 x 250</p>
            </div>
            <div class="BJS-login card">
                <div class="card-body p-0">
                    <div class="d-flex flex-column h-100">
                        <div class="join-background ">
                            <h1 class="text-center mt-4 pt-2">JOIN</h1>
                            <h5 class="text-center mt-1">BJS Academy</h5>
                        </div>
                        <div class="d-flex justify-content-center w-100 position-absolute ">
                            <span class="circle-arrow"><i class="fa-solid fa-right-long"></i></span>

                        </div>
                        <div class="h-50 ">
                            <p class="text-left join-text mt-4">Join BJS Academy And
                                Receive Our Latest News
                                And Updates.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div> -->
<section id="about-section" class="container-fluid" [ngStyle]="{'padding-top':aboutPadding==0?'190px':aboutPadding+'px'}" >
    <app-about *ngIf="!aboutPageLoader ; else PageAboutNotLoaded"></app-about>
    <ng-template #PageAboutNotLoaded>
        <app-about [pageloading]="aboutPageLoader"></app-about>
    </ng-template>
</section>