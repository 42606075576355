import { AfterViewInit, Component, ElementRef, Input, OnInit, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { SharedService } from '../../services/shared.service';
import { ArticleService } from '../../services/article.service';
import { Subscription } from 'rxjs';
import { env } from '../../config/config';
import { ActivatedRoute, Router, UrlSegment } from '@angular/router';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
    selector: 'app-top-rated',
    templateUrl: './top-rated.component.html',
    styleUrl: './top-rated.component.scss'
})
export class TopRatedComponent implements OnInit,AfterViewInit {
    showMoreDataBadgeLeft = false;
    showMoreDataBadgeRight = false;
    @Input() pageloading: boolean;
    @Input() articleData: any;
    @Input() searchValue: string;
    @Input() pageType:any;
    @Input() contentType: string[];
    @Input() surgicalSpecialty: string[];
    category: string;
    safeSummary!: SafeHtml;
	safeTitle!: SafeHtml;
    journalid: string;
    top_rated: any;
    rct_data: any;
    subscriptions: Subscription[] = [];
    coverImageUrl: string = '';
	@ViewChild('content') contentElements: ElementRef<HTMLDivElement> | undefined;
	@ViewChild('maindiv') maindivElement: ElementRef<HTMLDivElement> | undefined;
    offsetHeight: any;
	scrollHeight: any;
    constructor(
        public sharedService: SharedService,
        private articleService: ArticleService,
        private renderer: Renderer2,
        private sanitizer:DomSanitizer,
        private router: Router
    ) {}

    ngOnInit() {
        this.journalid = this.sharedService.getJournalId();
        if (this.journalid) {

            const params: any = {
                length: 1,
                start: 0,
                top_rct: 'top'
            };

            const rctSub = this.articleService.getArticleList(this.journalid, params).subscribe(result => {
                this.top_rated = result.data[0];
            })
            this.subscriptions.push(rctSub);

            const nextparams: any = {
                length: 1,
                start: 0,
                top_rct: 'rct'
            };

            const topSub = this.articleService.getArticleList(this.journalid, nextparams).subscribe(result => {
                this.rct_data = result.data[0];
            })
            this.subscriptions.push(topSub);
        }
    }
    ngAfterViewInit() {
        this.scrollHeight = this.contentElements?.nativeElement.scrollHeight;
		this.offsetHeight = this.contentElements?.nativeElement.offsetHeight;
		if (this.scrollHeight > this.offsetHeight) {
			this.renderer.setStyle(this.maindivElement?.nativeElement.querySelector("[class='txtcol']"), 'display', 'contents');
			this.maindivElement?.nativeElement.classList.add('truncate');			
		}
    }
    showMoreBadgesLeft() {
        this.showMoreDataBadgeLeft = !this.showMoreDataBadgeLeft;
    }
    showMoreBadgesRight() {
        this.showMoreDataBadgeRight = !this.showMoreDataBadgeRight;
    }

    returnCoverImage(article_data: any) {
        this.coverImageUrl = env.articleCoverImage + this.journalid + '/';
        if (article_data?.article_cover_image) {
            this.coverImageUrl =
                this.coverImageUrl + article_data?.category_heading?.id + '/' + article_data?.article_id + '/' + article_data?.article_cover_image;
        }
        else {
            this.coverImageUrl = 'bjs_article_cover_image.png';
        }
        return this.coverImageUrl;
    }


    isStringArray(authors: any[]): boolean {
        return typeof authors[0] === 'string';
    }

    isObjectArray(authors: any[]): boolean {
        return typeof authors[0] === 'object';
    }

    returnSummary(summary:string){
        return this.sanitizer.bypassSecurityTrustHtml(summary);
    }

    returnCategory(category:string){
        if(category == 'social-media' ||category == 'randomized-clinical-trials'||category == 'surgical-science' || category == 'surgery-for-all'){
            return true;
        }
        else{
            return false;
        }
    }

    filterClicked(value: string, filterType: string) {
	
		// Determine which filter was clicked
		let queryParams: any = {
		  search: this.searchValue
		};
	  
		if (filterType === 'type') {
		  queryParams['contentType'] = value;
		} else if (filterType === 'speciality') {
		  queryParams['speciality'] = value;
		} else if (filterType === 'category') {
		  queryParams['category'] = value;
		}
	  
		// Navigate based on the pageType
		if (this.pageType === undefined || this.pageType === "allpage") {
		  this.router.navigate(['/bjs-academy', 'all-articles', 'page', '1'], { queryParams });
		} else if (this.pageType === "listpage") {
		  this.router.navigate(['/bjs-academy', this.category, 'page', '1'], { queryParams });
		}
    }
	  
    categoryClick( value: any,title:any) {
		let queryParams: any = {
			search: this.searchValue
		  };
		{
			queryParams['category'] = value;
		  }
		// Navigate to the desired route
		this.router.navigate(['/bjs-academy', 'scientific-surgery', value, 'page', '1'],{ queryParams });
	}

    highlightType(type: string): SafeHtml {
		if (!this.contentType || !Array.isArray(this.contentType)) {
		  return this.sanitizer.bypassSecurityTrustHtml(type);
		}
	  
		// const isHighlighted = Array.isArray(this.contentType) && this.contentType.includes(type);
		// const className = isHighlighted ? 'highlight' : '';
		// const html = `<span class="${className}">${type}</span>`;

		// Perform highlighting for each name in surgicalSpecialty
		for (const name of this.contentType) {
			const regex = new RegExp('\\b' + name + '\\b', 'gi');
			type = type.replace(regex, `<span style="background-color: yellow;
			  border-radius: 5px;
			  color:black;
			  padding: 0px 5px;
			  border-style: solid;
			  border-color: rgb(85, 85, 221);
			  border-width: 1px;
			  display: inline-block;">$&</span>`);
		  }
		return this.sanitizer.bypassSecurityTrustHtml(type);
	}

    highlightReference(tag: string): SafeHtml {
		// Check if surgicalSpecialty is defined and is an array
		if (!this.surgicalSpecialty || !Array.isArray(this.surgicalSpecialty)) {
		  return this.sanitizer.bypassSecurityTrustHtml(tag);
		}
	  
		// Perform highlighting for each name in surgicalSpecialty
		for (const name of this.surgicalSpecialty) {
		  const regex = new RegExp('\\b' + name + '\\b', 'gi');
		  tag = tag.replace(regex, `<span style="background-color: yellow;
			border-radius: 5px;
			color:black;
			padding: 0px 5px;
			border-style: solid;
			border-color: rgb(85, 85, 221);
			border-width: 1px;
			display: inline-block;">$&</span>`);
		}
	  
		return this.sanitizer.bypassSecurityTrustHtml(tag);
    }

    ngOnDestroy() {
        this.subscriptions.forEach(x => {
            if (!x.closed) {
                x.unsubscribe();
            }
        });
    }
}
